import localforage from "localforage";
import Vue from "vue";
import useEvents from "~/mixins/useEvents";

export default async ({ app, store, route, ...args }) => {
  localforage
    .clear()
    .then(() => console.log("localforage cleared"))
    .catch((err) => console.error(err)); // reset cache on reload APP
  await store.dispatch("nuxtClientInit", route);

  const EventsComponent = Vue.extend({
    mixins: [useEvents],
    computed: {
      currentApp() {
        return app;
      },
    },
    store,
    ...args,
  });
  const eventsInstance = new EventsComponent();
  eventsInstance.visitEvent();
};
