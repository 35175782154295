import { mapGetters } from "vuex";
import { addDays } from "date-fns";
import {
  getProductImages,
  removeHTMLTagFromString,
  getImageByPosition,
} from "~/utils";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      settings: "general/getSettings",
      breadcrumbs: "general/getBreadcrumbs",
      jsonSchemaData: "general/getJsonSchemaData",
    }),
    cleanFrontDomain() {
      const frontDomain = this.settings?.front_domain;
      return frontDomain && frontDomain?.trim()?.endsWith("/")
        ? frontDomain.slice(0, -1)
        : frontDomain;
    },
    baseUrl() {
      return this.cleanFrontDomain || process.env.DOMAIN || "";
    },
    url() {
      return `${this.baseUrl}${this.$route.path}`;
    },
  },
  methods: {
    getBaseSchemaPayload() {
      return {
        "@context": "https://schema.org",
        "@type": "WebPage",
        name: this.jsonSchemaData.pageName || this.settings?.company_name, // must be page title by default
        url: this.url,
        image:
          this.jsonSchemaData.pageImage || this.settings?.desktop_logo || "",
      };
    },
    getSchemaBreadcrumbs() {
      if (!this.breadcrumbs?.length) {
        return null;
      }
      return {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: this.breadcrumbs?.map((el, idx) => ({
          "@type": "ListItem",
          position: idx + 1,
          item: {
            "@type": "WebPage",
            "@id": `${this.baseUrl}${this.localePath(el.href)}`,
            name: el.text,
            url: `${this.baseUrl}${this.localePath(el.href)}`,
          },
        })),
      };
    },
    setPageJsonLdSchema() {
      return {
        ...this.getBaseSchemaPayload(),
        breadcrumb: this.getSchemaBreadcrumbs(),
      };
    },
    setHomePageJsonLdSchema() {
      const payload = {
        ...this.getBaseSchemaPayload(),
        provider: {
          "@context": "https://schema.org",
          "@type": "Organization",
          email: this.settings?.company_address?.email_address,
          telephone: this.settings?.company_address?.phone_number,
          openingHours: this.settings?.working_hours,
          name: this.settings?.company_name,
          image: this.settings?.desktop_logo,
        },
      };
      if (this.settings?.company_address) {
        payload.provider.address = {
          "@type": "PostalAddress",
          streetAddress:
            (this.settings?.company_address?.address_line_1 || "") +
            (this.settings?.company_address?.address_line_2
              ? ", " + this.settings?.company_address?.address_line_2
              : ""),
          addressLocality: this.settings?.company_address?.city,
          addressRegion: this.settings?.company_address?.state,
          addressCountry: this.settings?.company_address?.country,
          postalCode: this.settings?.company_address?.zip,
        };
      }
      return payload;
    },
    setPLPPageLdSchema() {
      return {
        ...this.getBaseSchemaPayload(),
        breadcrumb: this.getSchemaBreadcrumbs(),
        mainEntity: {
          "@context": "https://schema.org",
          "@type": "ItemList",
          url: `${this.baseUrl}${this.$route.fullPath}`,
          numberOfItems: this.jsonSchemaData.numberOfItems,
          itemListElement: this.jsonSchemaData.products?.map((product) =>
            this.getBaseProductSchemaPayload(product)
          ),
        },
      };
    },
    getBaseProductSchemaPayload(product) {
      const productTitle =
        this.jsonSchemaData?.productTitle ||
        product?.variant_plp_image?.mixedTranslation?.title ||
        product?.default_config?.mixedTranslation?.title ||
        product?.mixedTranslation?.title ||
        "";

      const productDescription =
        this.jsonSchemaData?.productDescription ||
        product?.variant_plp_image?.mixedTranslation?.description ||
        product?.default_config?.mixedTranslation?.description ||
        product?.mixedTranslation?.long_description ||
        "";

      const productPrice = Number(
        this.jsonSchemaData?.productPrice ||
          product?.variant_plp_image?.cost ||
          product?.default_config?.cost ||
          product?.base_cost ||
          0
      );

      const discountValue = (() => {
        if (!product?.sale || !product?.sale_value || !product) {
          return 0;
        }

        return Number(
          (productPrice - productPrice * (+product.sale_value / 100)).toFixed(2)
        );
      })();

      const productImageUrl =
        this.jsonSchemaData?.productImage ||
        getProductImages(product)?.[0]?.url ||
        "/images/no-image.webp";

      const productSKU =
        this.jsonSchemaData?.productSku ||
        product?.variant_plp_image?.sku ||
        product?.default_config?.configuration_id ||
        product?.default_sku;

      return {
        "@type": "Product",
        image: productImageUrl,
        url:
          this.jsonSchemaData?.productUrl ||
          `${this.baseUrl}${this.localePath(`/product/${product?.slug}`)}`,
        name: productTitle,
        description: removeHTMLTagFromString(productDescription),
        sku: productSKU,
        brand: this.settings?.company_name,

        offers: {
          "@type": "Offer",
          priceCurrency: this.settings?.currency_code || "",
          price: (discountValue || productPrice || 0).toFixed(2),
          priceValidUntil: addDays(new Date(), 30).toISOString(),
          availability: "https://schema.org/InStock",
          url:
            this.jsonSchemaData?.productUrl ||
            `${this.baseUrl}${this.localePath(`/product/${product?.slug}`)}`,
        },
      };
    },
    hashCode(s) {
      /**
       * Using the java hashCode function
       * https://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
       * https://www.thejavaprogrammer.com/what-is-hashcode-in-java/
       */
      let hash = 0;
      for (let i = 0; i < s.length; i += 1) {
        const char = s.charCodeAt(i);
        hash = (hash << 5) - hash + char; // eslint-disable-line no-bitwise
        hash &= hash; // eslint-disable-line no-bitwise
      }
      return hash;
    },
    getJsonLdScriptHid(payload) {
      const minifiedString = JSON.stringify(payload, null, "");
      return `nuxt-jsonld-${this.hashCode(minifiedString).toString(16)}`;
    },
    setProductPageLdSchema() {
      return {
        ...this.getBaseSchemaPayload(),
        breadcrumb: this.getSchemaBreadcrumbs(),
        mainEntity: {
          "@context": "https://schema.org",
          ...this.getBaseProductSchemaPayload(this.jsonSchemaData.product),
        },
      };
    },
    setBlogListPageLdSchema() {
      return {
        ...this.getBaseSchemaPayload(),
        breadcrumb: this.getSchemaBreadcrumbs(),
        mainEntity: {
          "@context": "https://schema.org",
          "@type": "Blog",
          image:
            this.jsonSchemaData?.pageImage || this.settings?.desktop_logo || "",
          name: this.jsonSchemaData?.pageName || this.settings?.company_name,
          url: `${this.baseUrl}${this.$route.path}`,
          "@id": `${this.baseUrl}${this.$route.path}`,
          mainEntityOfPage: `${this.baseUrl}${this.$route.path}`,
          blogPost: this.jsonSchemaData?.posts?.map((post) => ({
            ...this.getBaseBlogSchemaPayload(post, { imagePosition: "list" }),
          })),
        },
      };
    },
    getBaseBlogSchemaPayload(post, options) {
      const postUrl =
        options?.id ||
        `${this.baseUrl}${this.localePath(
          `/articles/${post?.categories?.[0]?.slug || "all"}/${post.slug}`
        )}`;
      return {
        "@type": "BlogPosting",
        "@id": postUrl,
        url: postUrl,
        // mainEntityOfPage: `${baseUrl}/blog/${blog?.categories[0]?.slug || "all"}/${blog.slug}`,
        headline: post?.mixedTranslation?.title || "",
        name: post?.mixedTranslation?.title || "",
        description: removeHTMLTagFromString(
          post?.mixedTranslation?.meta_description ||
            post?.mixedTranslation?.short_description ||
            ""
        ),
        datePublished: post.created_at,
        dateModified: post.updated_at,
        // author: { // TODO define who is author
        //   "@type": "Person",
        //   "@id": "https://dataliberate.com/author/richard-wallis/#Person",
        //   name: "Richard Wallis",
        // },
        image:
          getImageByPosition(post.medias, options.imagePosition)?.file?.url ||
          "/images/no-image.webp",
      };
    },
    setBlogPostPageLdSchema() {
      return {
        ...this.getBaseSchemaPayload(),
        breadcrumb: this.getSchemaBreadcrumbs(),
        mainEntity: {
          ...this.getBaseBlogSchemaPayload(this.jsonSchemaData?.post, {
            id: this.url,
            imagePosition: "page",
          }),
          isPartOf: {
            "@type": "Blog",
            "@id": `${this.baseUrl}${this.localePath("/articles")}`,
            name: this.$t("articles"),
            publisher: {
              "@type": "Organization",
              "@id": this.baseUrl + this.localePath("/"),
              name: this.settings?.company_name,
            },
          },
        },
      };
    },
  },
};
