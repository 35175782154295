export const state = () => ({
  wishlist: [],
});

export const getters = {
  getWishList(state) {
    return state.wishlist;
  },
};

export const mutations = {
  SET_WISHLIST(state, payload) {
    if (!payload) {
      return;
    }
    const mapedPayload = payload.map((el) => ({
      ...el,
      engraving: Array.isArray(el.engraving) ? {} : el.engraving,
    }));
    state.wishlist = mapedPayload;

    const localStorageData =
      mapedPayload.map((el) => ({
        engraving: el.engraving,
        sku: el.sku,
      })) || [];
    localStorage.setItem(
      "conf-m8ht77-wishlist",
      JSON.stringify(localStorageData)
    );
  },
  RESET_WISHLIST(state) {
    state.wishlist = [];
  },
};

export const actions = {
  setWishlist({ commit, dispatch, rootGetters }, payload) {
    // console.log("payload - ", payload);
    commit("SET_WISHLIST", payload);
    dispatch("updateWishlistId", rootGetters.getWishListId, { root: true });
  },
};
