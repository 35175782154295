import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_plugin_2cd249f0 from 'nuxt_plugin_plugin_2cd249f0' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_gtm_0b23ef18 from 'nuxt_plugin_gtm_0b23ef18' // Source: ./gtm.js (mode: 'all')
import nuxt_plugin_pluginutils_6989dbdd from 'nuxt_plugin_pluginutils_6989dbdd' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_36fa3430 from 'nuxt_plugin_pluginrouting_36fa3430' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_7dda0e87 from 'nuxt_plugin_pluginmain_7dda0e87' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_vlazyload_69763ee8 from 'nuxt_plugin_vlazyload_69763ee8' // Source: ./v-lazy-load.js (mode: 'all')
import nuxt_plugin_nuxtvuexlocalstorage_31bf50f9 from 'nuxt_plugin_nuxtvuexlocalstorage_31bf50f9' // Source: ./nuxt-vuex-localstorage.js (mode: 'client')
import nuxt_plugin_axios_7823521e from 'nuxt_plugin_axios_7823521e' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_2420f5c6 from 'nuxt_plugin_cookieuniversalnuxt_2420f5c6' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_uidclient_e1024044 from 'nuxt_plugin_uidclient_e1024044' // Source: ../plugins/uid-client (mode: 'client')
import nuxt_plugin_axios_3566aa80 from 'nuxt_plugin_axios_3566aa80' // Source: ../plugins/axios (mode: 'all')
import nuxt_plugin_components_6fb0430c from 'nuxt_plugin_components_6fb0430c' // Source: ../plugins/components (mode: 'all')
import nuxt_plugin_vmask_3801a64e from 'nuxt_plugin_vmask_3801a64e' // Source: ../plugins/v-mask (mode: 'all')
import nuxt_plugin_methods_5f5138fb from 'nuxt_plugin_methods_5f5138fb' // Source: ../plugins/methods.js (mode: 'all')
import nuxt_plugin_priceformat_11891aa8 from 'nuxt_plugin_priceformat_11891aa8' // Source: ../plugins/price-format.js (mode: 'all')
import nuxt_plugin_pagination_509610e0 from 'nuxt_plugin_pagination_509610e0' // Source: ../plugins/pagination (mode: 'client')
import nuxt_plugin_veevalidate_6e5ad03a from 'nuxt_plugin_veevalidate_6e5ad03a' // Source: ../plugins/vee-validate (mode: 'all')
import nuxt_plugin_zoom_6a899449 from 'nuxt_plugin_zoom_6a899449' // Source: ../plugins/zoom (mode: 'client')
import nuxt_plugin_api_22834091 from 'nuxt_plugin_api_22834091' // Source: ../plugins/api.js (mode: 'all')
import nuxt_plugin_cacheControl_50fd3028 from 'nuxt_plugin_cacheControl_50fd3028' // Source: ../plugins/cacheControl.js (mode: 'all')
import nuxt_plugin_integrations_9913937c from 'nuxt_plugin_integrations_9913937c' // Source: ../plugins/integrations.js (mode: 'client')
import nuxt_plugin_nuxtclientinit_3893cbb9 from 'nuxt_plugin_nuxtclientinit_3893cbb9' // Source: ../plugins/nuxt-client-init.js (mode: 'client')
import nuxt_plugin_systempopups_564ba1b4 from 'nuxt_plugin_systempopups_564ba1b4' // Source: ../plugins/system-popups.js (mode: 'all')
import nuxt_plugin_appconfig_d5345556 from 'nuxt_plugin_appconfig_d5345556' // Source: ../plugins/app-config.js (mode: 'all')
import nuxt_plugin_breakpoints_068c9f09 from 'nuxt_plugin_breakpoints_068c9f09' // Source: ../plugins/breakpoints.js (mode: 'client')
import nuxt_plugin_sticky_a264e00c from 'nuxt_plugin_sticky_a264e00c' // Source: ../plugins/sticky.js (mode: 'client')
import nuxt_plugin_vresize_7398fc90 from 'nuxt_plugin_vresize_7398fc90' // Source: ../plugins/v-resize.js (mode: 'client')
import nuxt_plugin_vvisible_0c0aac20 from 'nuxt_plugin_vvisible_0c0aac20' // Source: ../plugins/v-visible.js (mode: 'client')
import nuxt_plugin_clickoutside_2f27d4ea from 'nuxt_plugin_clickoutside_2f27d4ea' // Source: ../plugins/click-outside.js (mode: 'client')
import nuxt_plugin_portalvue_8244bc84 from 'nuxt_plugin_portalvue_8244bc84' // Source: ../plugins/portal-vue.js (mode: 'client')
import nuxt_plugin_sitepopups_7178b580 from 'nuxt_plugin_sitepopups_7178b580' // Source: ../plugins/site-popups.js (mode: 'client')
import nuxt_plugin_discountcampaigns_4068c50a from 'nuxt_plugin_discountcampaigns_4068c50a' // Source: ../plugins/discount-campaigns.js (mode: 'client')
import nuxt_plugin_setsitesettings_9f587822 from 'nuxt_plugin_setsitesettings_9f587822' // Source: ../plugins/set-site-settings.js (mode: 'server')
import nuxt_plugin_detectbrowserlanguage_32ce9b44 from 'nuxt_plugin_detectbrowserlanguage_32ce9b44' // Source: ../plugins/detect-browser-language.js (mode: 'server')
import nuxt_plugin_builderplugin_204ce57c from 'nuxt_plugin_builderplugin_204ce57c' // Source: ../plugins/builder-plugin.js (mode: 'server')
import nuxt_plugin_remain_5734e6d2 from 'nuxt_plugin_remain_5734e6d2' // Source: ../plugins/remain.js (mode: 'client')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"Omara","htmlAttrs":{"lang":"en"},"bodyAttrs":{"class":"home-minimal"},"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":"Modern jewelry designed to reinforce a deeper connection to ourselves & the world around us."},{"name":"format-detection","content":"telephone=no"},{"hid":"apple-mobile-web-app-title","name":"apple-mobile-web-app-title","content":"Omara"},{"hid":"og:title","name":"og:title","property":"og:title","content":"Omara"},{"hid":"og:site_name","name":"og:site_name","property":"og:site_name","content":"omara.com"},{"hid":"og:description","name":"og:description","property":"og:description","content":"Modern jewelry designed to reinforce a deeper connection to ourselves & the world around us."},{"hid":"google-site-verification","name":"google-site-verification","content":"gVt96HfHzUHu2xEQRV2E331UAXgSZgbuVJpqfMbLLgs"}],"link":[],"style":[],"script":[{"hid":"gtm-script","innerHTML":"if(!window._gtm_init){window._gtm_init=1;(function(w,n,d,m,e,p){w[d]=(w[d]==1||n[d]=='yes'||n[d]==1||n[m]==1||(w[e]&&w[e][p]&&w[e][p]()))?1:0})(window,navigator,'doNotTrack','msDoNotTrack','external','msTrackingProtectionEnabled');(function(w,d,s,l,x,y){w[x]={};w._gtm_inject=function(i){if(w.doNotTrack||w[x][i])return;w[x][i]=1;w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s);j.async=true;j.src='https:\u002F\u002Fwww.googletagmanager.com\u002Fgtm.js?id='+i;f.parentNode.insertBefore(j,f);}})(window,document,'script','dataLayer','_gtm_ids','_gtm_inject')}"}],"noscript":[{"hid":"gtm-noscript","pbody":true,"innerHTML":""}],"__dangerouslyDisableSanitizersByTagID":{"gtm-script":["innerHTML"],"gtm-noscript":["innerHTML"]}},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_2cd249f0 === 'function') {
    await nuxt_plugin_plugin_2cd249f0(app.context, inject)
  }

  if (typeof nuxt_plugin_gtm_0b23ef18 === 'function') {
    await nuxt_plugin_gtm_0b23ef18(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_6989dbdd === 'function') {
    await nuxt_plugin_pluginutils_6989dbdd(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_36fa3430 === 'function') {
    await nuxt_plugin_pluginrouting_36fa3430(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_7dda0e87 === 'function') {
    await nuxt_plugin_pluginmain_7dda0e87(app.context, inject)
  }

  if (typeof nuxt_plugin_vlazyload_69763ee8 === 'function') {
    await nuxt_plugin_vlazyload_69763ee8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_nuxtvuexlocalstorage_31bf50f9 === 'function') {
    await nuxt_plugin_nuxtvuexlocalstorage_31bf50f9(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_7823521e === 'function') {
    await nuxt_plugin_axios_7823521e(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_2420f5c6 === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_2420f5c6(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_uidclient_e1024044 === 'function') {
    await nuxt_plugin_uidclient_e1024044(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_3566aa80 === 'function') {
    await nuxt_plugin_axios_3566aa80(app.context, inject)
  }

  if (typeof nuxt_plugin_components_6fb0430c === 'function') {
    await nuxt_plugin_components_6fb0430c(app.context, inject)
  }

  if (typeof nuxt_plugin_vmask_3801a64e === 'function') {
    await nuxt_plugin_vmask_3801a64e(app.context, inject)
  }

  if (typeof nuxt_plugin_methods_5f5138fb === 'function') {
    await nuxt_plugin_methods_5f5138fb(app.context, inject)
  }

  if (typeof nuxt_plugin_priceformat_11891aa8 === 'function') {
    await nuxt_plugin_priceformat_11891aa8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_pagination_509610e0 === 'function') {
    await nuxt_plugin_pagination_509610e0(app.context, inject)
  }

  if (typeof nuxt_plugin_veevalidate_6e5ad03a === 'function') {
    await nuxt_plugin_veevalidate_6e5ad03a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_zoom_6a899449 === 'function') {
    await nuxt_plugin_zoom_6a899449(app.context, inject)
  }

  if (typeof nuxt_plugin_api_22834091 === 'function') {
    await nuxt_plugin_api_22834091(app.context, inject)
  }

  if (typeof nuxt_plugin_cacheControl_50fd3028 === 'function') {
    await nuxt_plugin_cacheControl_50fd3028(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_integrations_9913937c === 'function') {
    await nuxt_plugin_integrations_9913937c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_nuxtclientinit_3893cbb9 === 'function') {
    await nuxt_plugin_nuxtclientinit_3893cbb9(app.context, inject)
  }

  if (typeof nuxt_plugin_systempopups_564ba1b4 === 'function') {
    await nuxt_plugin_systempopups_564ba1b4(app.context, inject)
  }

  if (typeof nuxt_plugin_appconfig_d5345556 === 'function') {
    await nuxt_plugin_appconfig_d5345556(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_breakpoints_068c9f09 === 'function') {
    await nuxt_plugin_breakpoints_068c9f09(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sticky_a264e00c === 'function') {
    await nuxt_plugin_sticky_a264e00c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vresize_7398fc90 === 'function') {
    await nuxt_plugin_vresize_7398fc90(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vvisible_0c0aac20 === 'function') {
    await nuxt_plugin_vvisible_0c0aac20(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_clickoutside_2f27d4ea === 'function') {
    await nuxt_plugin_clickoutside_2f27d4ea(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_portalvue_8244bc84 === 'function') {
    await nuxt_plugin_portalvue_8244bc84(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sitepopups_7178b580 === 'function') {
    await nuxt_plugin_sitepopups_7178b580(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_discountcampaigns_4068c50a === 'function') {
    await nuxt_plugin_discountcampaigns_4068c50a(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_setsitesettings_9f587822 === 'function') {
    await nuxt_plugin_setsitesettings_9f587822(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_detectbrowserlanguage_32ce9b44 === 'function') {
    await nuxt_plugin_detectbrowserlanguage_32ce9b44(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_builderplugin_204ce57c === 'function') {
    await nuxt_plugin_builderplugin_204ce57c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_remain_5734e6d2 === 'function') {
    await nuxt_plugin_remain_5734e6d2(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
