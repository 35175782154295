
import { mapGetters } from "vuex";
import { setCustomProperty } from "~/utils";

export default {
  data() {
    return {
      visible: false,
    };
  },
  computed: {
    ...mapGetters({
      getDiscountCampaignsData: "general/getDiscountCampaignsData",
    }),
  },

  watch: {
    visible: {
      handler() {
        this.setHeight();
      },
    },
  },
  beforeDestroy() {
    const root = document.documentElement;
    root.style.removeProperty("--banner-height");
  },
  methods: {
    setHeight() {
      setCustomProperty(
        "--banner-height",
        `${this.$refs.topBanner?.offsetHeight || 0}px`
      );
      setCustomProperty(
        "--banner-visible-height",
        `${this.visible ? this.$refs.topBanner?.offsetHeight || 0 : 0}px`
      );
    },
    setVisible(isVisible) {
      this.visible = isVisible;
    },
  },
};
