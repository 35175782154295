export default {
  bind(el, binding) {
    // Create a resize observer and attach it to the element
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        // Call the provided callback function on resize
        if (binding.value && typeof binding.value === "function") {
          binding.value(entry);
        }
      }
    });

    el._resizeObserver = resizeObserver;
    resizeObserver.observe(el);
  },
  unbind(el) {
    // Clean up the observer when the directive is unbound
    if (el._resizeObserver) {
      el._resizeObserver.unobserve(el);
      el._resizeObserver.disconnect();
      delete el._resizeObserver;
    }
  },
};
