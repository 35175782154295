export default {
  methods: {
    textLink(e) {
      const textLink = e.target.closest("a");
      if (textLink) {
        e.stopPropagation();
        const link = textLink.href;
        if (link) {
          const url = new URL(link);
          if (window.location.host === url.host && !url.hash) {
            this.$router.push(this.localePath(url.pathname + url.search));
          }
        }
      }
    },
  },
};
