export default {
  bind(el, binding) {
    const callback = binding.value;

    // Ensure the callback is a function
    if (typeof callback !== "function") {
      console.warn(
        "Expecting a function as a callback for v-visible directive"
      );
      return;
    }

    setTimeout(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          // Execute the callback function with the visibility state
          callback(entry.isIntersecting, entry);
        },
        {
          threshold: 0.1, // Adjust the threshold as needed
        }
      );

      el._observer = observer;
      observer.observe(el);
    });
  },
  unbind(el) {
    // Clean up the observer when the directive is unbound
    if (el._observer) {
      el._observer.disconnect();
      delete el._observer;
    }
  },
};
