
import { mapGetters } from "vuex";
import { metaGenerator } from "~/utils";
import builderLayoutMixin from "~/builder/builderLayoutMixin";
import MarketingTopBanner from "~/components/common/MarketingTopBanner.vue";

export default {
  components: {
    MarketingTopBanner,
    SystemPopup: () => import("~/components/popups/SystemPopup"),
    AppFooter: () => import("~/components/common/AppFooter"),
    Header: () => import("@/components/common/Header.vue"),
    SideMenu: () => import("~/components/common/SideMenu.vue"),
  },
  mixins: [builderLayoutMixin],
  layout: "empty",
  props: ["error"],
  head() {
    return {
      meta: [
        ...metaGenerator({
          title: this.getCompanyName || "",
          description: this.getCompanyName || "",
        }),
      ],
      title: this.getCompanyName || "",
    };
  },
  computed: {
    ...mapGetters({
      getCompanyName: "general/getCompanyName",
    }),
  },
  created() {
    // if (String(this.error.statusCode).match(/^5\d{2}$/)) {
    //   this.$router.push("/500");
    // }
  },
};
