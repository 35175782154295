export const fileTypes = {
  video: "video",
  image: "image",
};

export const countries = [
  {
    code: "US",
    label: "United States",
  },
  {
    code: "AT",
    label: "Österreich",
  },
  {
    code: "BE",
    label: "België",
  },
  {
    code: "BG",
    label: "България",
  },
  {
    code: "CY",
    label: "Κύπρος",
  },
  {
    code: "CZ",
    label: "Česko",
  },
  {
    code: "DE",
    label: "Deutschland",
  },
  {
    code: "DK",
    label: "Danmark",
  },
  {
    code: "EE",
    label: "Eesti",
  },
  {
    code: "EL",
    label: "Ελλάδα",
  },
  {
    code: "ES",
    label: "España",
  },
  {
    code: "FI",
    label: "Suomi",
  },
  {
    code: "FR",
    label: "France",
  },
  {
    code: "HR",
    label: "Hrvatska",
  },
  {
    code: "HU",
    label: "Magyarország",
  },
  {
    code: "IE",
    label: "Ireland",
  },
  {
    code: "IT",
    label: "Italia",
  },
  {
    code: "LT",
    label: "Lietuva",
  },
  {
    code: "LU",
    label: "Lëtzebuerg",
  },
  {
    code: "LV",
    label: "Latvija",
  },
  {
    code: "MT",
    label: "Malta",
  },
  {
    code: "NL",
    label: "Nederland",
  },
  {
    code: "PL",
    label: "Polska",
  },
  {
    code: "PT",
    label: "Portugal",
  },
  {
    code: "RO",
    label: "România",
  },
  {
    code: "SE",
    label: "Sverige",
  },
  {
    code: "SI",
    label: "Slovenija",
  },
  {
    code: "SK",
    label: "Slovensko",
  },
];
export const PAYMENT_METHODS = {
  CARD: "card",
  PAYPAL: "paypal",
  PAYPAL_EXPRESS: "paypal_express",
  AFFIRM: "affirm",
  STRIPE: "stripe",
  AFFIRM_EXPRESS: "affirm_express",
  GOOGLE_PAY: "google_pay",
  APPLE_PAY: "apple_pay",
  STRIPE_CHECKOUT_FORM: "stripe_checkout_form",
  BANK_TRANSFER: "bank_transfer",
};

export const STRIPE_CHECKOUT_FORM_STATUSES = {
  OPEN: "open",
  COMPLETE: "complete",
};

export const PAYMENT_STATUSES = {
  0: "unpaid",
  1: "paid",
  5: "refundStarted",
  2: "refunded",
  3: "partiallyRefunded",
  4: "voided",
};

export const USER_LOCALE_COOKIE_MAX_AGE = 60 * 60 * 24 * 3;

export const CACHE_API_ENDPOINTS = {
  bootstrap: "system_settings",
  "system-catalog": "product",
  products: "product",
  collections: "collection",
  pages: "page",
  articles: "posts",
  blog: "posts",
  posts: "posts",
  "system-entities": "page",
  blocks: "page",
  catalog: "product",
  filters: "product",
  popups: "page",
  "product-configurations": "product",
  "campaign-discount": "page",
  "payment-gateways": "payment_gateways",
  "payment-methods": "payment_methods",
};

export const API_REQUESTS_CACHE_TIME = 600000; // 10 minutes

export const EVENTS_ID = {
  VISIT: "visit",
  ADD_TO_CART: "add_to_cart",
  REMOVE_FROM_CART: "remove_from_cart",
  BEGIN_CHECKOUT: "begin_checkout",
  ADD_PAYMENT_INFO: "add_payment_info",
  ADD_SHIPPING_INFO: "add_shipping_info",
  CHECKOUT_ERROR: "checkout_error",
  PURCHASE: "purchase",
  SEARCH: "search",
  ADD_TO_FAVORITES: "add_to_favorites",
  REMOVE_FROM_FAVORITES: "remove_from_favorites",
  REMAIN: "remain",
};

export const EVENT_GENDERS = {
  NotSure: 0,
  Male: 1,
  Female: 2,
};

export const diamondKnowledgeOnSlug = {
  zasnuby: "zasnuby",
  engagement: "engagement",
};
