const middleware = {}

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['check-browser-language'] = require('../middleware/check-browser-language.js')
middleware['check-browser-language'] = middleware['check-browser-language'].default || middleware['check-browser-language']

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['login-redirect'] = require('../middleware/login-redirect.js')
middleware['login-redirect'] = middleware['login-redirect'].default || middleware['login-redirect']

middleware['menu'] = require('../middleware/menu.js')
middleware['menu'] = middleware['menu'].default || middleware['menu']

middleware['visit'] = require('../middleware/visit.js')
middleware['visit'] = middleware['visit'].default || middleware['visit']

export default middleware
