import { integrationsConfig } from "~/config/index.js";

export default ({ store }) => {
  function addScripts(str, to, pos, callbackData) {
    // Create an element outside the document to parse the string with
    const div = document.createElement("div");

    // Parse the string
    div.innerHTML = str;

    // Copy those nodes to the real `head`, duplicating script elements so
    // they get processed
    let node = div.firstChild;
    while (node) {
      let cloneNode = null;
      const next = node.nextSibling;
      if (node.tagName === "SCRIPT") {
        // Just appending this element wouldn't run it, we have to make a fresh copy
        const newNode = document.createElement("script");
        [...node.attributes].forEach((attr) => {
          newNode.setAttribute(attr.nodeName, attr.nodeValue || "");
        });
        while (node.firstChild) {
          // Note we have to clone these nodes
          newNode.appendChild(node.firstChild.cloneNode(true));
          node.removeChild(node.firstChild);
        }
        node = newNode;
      } else if (node.tagName === "NOSCRIPT") {
        // Just appending this element wouldn't run it, we have to make a fresh copy
        const newNode = document.createElement("noscript");
        [...node.attributes].forEach((attr) => {
          newNode.setAttribute(attr.nodeName, attr.nodeValue || "");
        });
        while (node.firstChild) {
          const parser = new DOMParser();
          const doc = parser.parseFromString(
            node.firstChild.nodeValue,
            "text/html"
          );
          while (doc.body.firstChild) {
            newNode.appendChild(doc.body.firstChild);
          }
          node.removeChild(node.firstChild);
        }
        node = newNode;
      }

      cloneNode = node.cloneNode(true);

      if (
        callbackData &&
        callbackData.integrationScriptHasSrc &&
        cloneNode.tagName === "SCRIPT" &&
        cloneNode.src
      ) {
        cloneNode.onload = () => {
          store.commit("general/SET_INTEGRATION_CALLBACK_STATUS", {
            integrationName: callbackData.integrationName,
            status: true,
          });
        };
      } else if (
        callbackData &&
        !callbackData.integrationScriptHasSrc &&
        cloneNode.tagName === "SCRIPT"
      ) {
        store.dispatch("general/setIntegrationStatusByObjectName", {
          integrationName: callbackData.integrationName,
          integrationObjectName: callbackData.integrationObjectName,
        });
      }

      if (pos === "start") {
        document[to].prepend(cloneNode);
      } else {
        document[to].append(cloneNode);
      }

      node = next;
    }
  }

  const integrations = store.state.general.integrations;

  const FacebookPixel =
    integrations?.[integrationsConfig.integrationsType.TYPE_FACEBOOK_PIXEL];

  if (FacebookPixel) {
    FacebookPixel.callbackData = {
      integrationName: "TYPE_FACEBOOK_PIXEL",
      integrationObjectName: "fbq",
      integrationScriptHasSrc: false,
    };
  }

  const GoogleAnalytics =
    integrations?.[integrationsConfig.integrationsType.TYPE_GOOGLE_ANALYTICS];

  if (GoogleAnalytics) {
    GoogleAnalytics.callbackData = {
      integrationName: "TYPE_GOOGLE_ANALYTICS",
      integrationObjectName: "dataLayer",
      integrationScriptHasSrc: false,
    };
  }

  const Hotjar =
    integrations?.[integrationsConfig.integrationsType.TYPE_HOTJAR];

  const ZendeskChat =
    integrations?.[integrationsConfig.integrationsType.TYPE_ZENDESK_CHAT];

  const CustomScripts =
    integrations?.[integrationsConfig.integrationsType.TYPE_CUSTOM_SCRIPTS] ||
    [];

  const scripts = [FacebookPixel, GoogleAnalytics, Hotjar, ZendeskChat];

  CustomScripts?.scripts?.forEach?.((script) => scripts.push(script));

  // setTimeout(() => {
  scripts.forEach((script) => {
    if (script) {
      let insertTo = "head";
      let position = "end";
      if (script.position === "start_body") {
        insertTo = "body";
        position = "start";
      }
      if (script.position === "end_body") {
        insertTo = "body";
        position = "end";
      }
      addScripts(script.script, insertTo, position, script.callbackData);
    }
  });
  // }, 0);
};
