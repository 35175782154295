import { render, staticRenderFns } from "./CatalogBlock.vue?vue&type=template&id=14d05efc&scoped=true&"
import script from "./CatalogBlock.vue?vue&type=script&lang=js&"
export * from "./CatalogBlock.vue?vue&type=script&lang=js&"
import style0 from "./CatalogBlock.vue?vue&type=style&index=0&id=14d05efc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14d05efc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiSpinner: require('/opt/atlassian/pipelines/agent/build/components/UI/UiSpinner.vue').default,UiPagination: require('/opt/atlassian/pipelines/agent/build/components/UI/UiPagination.vue').default})
