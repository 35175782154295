import { getRandomArrayItem } from "~/utils";

export default async ({ store, $api }) => {
  async function getDiscountCampaigns() {
    const res = await $api.general.getDiscountCampaigns();
    return res.data?.data;
  }

  const discountCampaigns = await getDiscountCampaigns();

  if (!discountCampaigns?.length) {
    return;
  }

  const fullDataDiscountCampaigns = discountCampaigns.filter(item => item.is_popup && item.mixedTranslation?.popup_text && item.is_top_bar_text && item.mixedTranslation?.top_bar_text);
  const topBannerDataDiscountCampaigns = discountCampaigns.filter(item => item.is_top_bar_text && item.mixedTranslation?.top_bar_text);

  let topBannerDataDiscountCampaign = null;
  if (fullDataDiscountCampaigns.length) {
    topBannerDataDiscountCampaign = getRandomArrayItem(fullDataDiscountCampaigns);
  } else if (topBannerDataDiscountCampaigns.length) {
    topBannerDataDiscountCampaign = getRandomArrayItem(topBannerDataDiscountCampaigns);
  }

  const discountCampaignsData = {
    topBanner: null,
    popups: discountCampaigns.filter(item => item.is_popup && item.mixedTranslation?.popup_text).map(item => ({
      discount_campaign_id: item.id,
      id: `discount_campaign_${item.id}`,
      frequency: item.frequency,
      mixedTranslation: {
        content: item.mixedTranslation.popup_text,
      },
      pages: item.pages,
    })),
    banners: discountCampaigns.filter(item => item.promotion_banner).map(item => ({
      mixedTranslation: {
        content: item.mixedTranslation.promotion_banner_text,
      },
      promotion_banner_file: item.promotion_banner_file,
      promotion_banner_options: item.promotion_banner_options,
      pages: item.pages,
    })),
  };

  if (topBannerDataDiscountCampaign) {
    discountCampaignsData.topBanner = {
      discount_campaign_id: topBannerDataDiscountCampaign.id,
      top_bar_text: topBannerDataDiscountCampaign.mixedTranslation.top_bar_text,
    };
  }

  await store.dispatch("general/setDiscountCampaignsData", discountCampaignsData);
};
