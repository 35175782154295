import { API_REQUESTS_CACHE_TIME } from "~/config";

export default (axios) => ({
  /**
   * Get blog categories
   *
   * @link https://devapi.omara.sk/apidoc/#api-Blog-GetApiV1BlogCategories
   * @param data
   * @returns {*}
   */
  getBlogCategories() {
    return axios.get("/blog/categories", {
      cache: {
        maxAge: API_REQUESTS_CACHE_TIME,
      },
    });
  },
  /**
   * Get blog category by slug
   *
   * @link https://devapi.omara.sk/apidoc/#api-Blog-GetApiV1BlogCategoriesSlug
   * @param data
   * @returns {*}
   */
  getBlogCategoryBySlug(slug) {
    return axios.get(`/blog/categories/${slug}`, {
      cache: {
        maxAge: API_REQUESTS_CACHE_TIME,
      },
    });
  },
  /**
   * Get blog category posts
   *
   * @link https://devapi.omara.sk/apidoc/#api-Blog-GetApiV1BlogCategoriesSlugPosts
   * @param data
   * @returns {*}
   */
  getPostsByCategory(slug, params) {
    console.log("params - ", params);
    return axios.get(`/blog/categories/${slug}/posts`, {
      params,
      cache: {
        maxAge: API_REQUESTS_CACHE_TIME,
      },
    });
  },
  /**
   * Get posts
   *
   * @link ???
   * @param data
   * @returns {*}
   */
  getPosts(params) {
    return axios.get("/blog/posts", {
      params,
      cache: {
        maxAge: API_REQUESTS_CACHE_TIME,
      },
    });
  },
  /**
   * Get post by slug
   *
   * @link https://devapi.omara.sk/apidoc/#api-Blog-GetApiV1BlogPostsTypeSlug
   * @param data
   * @returns {*}
   */
  getPostByType(type, slug) {
    return axios.get(`/blog/posts/${type}/${slug}`, {
      cache: {
        maxAge: API_REQUESTS_CACHE_TIME,
      },
    });
  },
  /**
   * Get posts by type
   *
   * @link https://devapi.omara.sk/apidoc/#api-Blog-GetApiV1BlogPostsType
   * @param data
   * @returns {*}
   */
  getPostsByType(type, slug) {
    return axios.get(`/blog/posts/${type}`, {
      cache: {
        maxAge: API_REQUESTS_CACHE_TIME,
      },
    });
  },
  /**
   * Get post by slug
   *
   * @link ????????
   * @param data
   * @returns {*}
   */
  getPostBySlug(slug) {
    return axios.get(`/blog/posts/${slug}`, {
      cache: {
        maxAge: API_REQUESTS_CACHE_TIME,
      },
    });
  },
});
